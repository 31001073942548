import React from 'react';
import EmailSignup from '../components/emailSignup';

// import PropTypes from 'prop-types';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons/';

function Footer() {
  return (
    <footer className="footer has-background-light">
      <div className="container">
        <div className="content has-text-centered">
          <div className="" style={{ maxWidth: 360, margin: '24px auto' }}>
            <EmailSignup title="Sign Up for Updates:" />
          </div>
          <div>
            {/* <a href="" className="">
              <span className="icon is-medium">
                <FontAwesomeIcon size="lg" icon={faFacebook} />
              </span>
            </a>
            <a href="" className="">
              <span className="icon is-medium">
                <FontAwesomeIcon size="lg" icon={faTwitter} />
              </span>
            </a> */}
            {/* <a href="" className="">
            <span className="icon is-medium">
              <FontAwesomeIcon size="lg" icon={faInstagram} />
            </span>
          </a> */}
          </div>
          <hr />
          <div
            className="is-size-7 is-uppercase"
            style={{
              display: 'inline-block',
              padding: '8px 16px',
              background: '#fff',
              border: '1px solid #222',
              borderRadius: 4,
              fontWeight: 'bold',
            }}
          >
            Authorized by the Committee to Elect Vanna Howard
          </div>
        </div>
      </div>
    </footer>
  );
}

// footer.propTypes = {};

export default Footer;
