import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Form  } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import addToMailchimp from 'gatsby-plugin-mailchimp';

function EmailSignup({ title }) {
  const [response, setResponse] = useState();

  const onSubmit = values => {
    const { email, ...listFields } = values;
    addToMailchimp(email, listFields) // listFields are optional if you are only capturing the email address.
      .then(data => {
        setResponse(data);
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      });
  };
  return (
    <div>
      {title && <p className="heading is-size-4">{title}</p>}
      {response && (
        <div
          className={
            response.result === 'error'
              ? 'notification is-medium is-danger is-light'
              : 'notification is-medium is-info is-light'
          }
          dangerouslySetInnerHTML={{ __html: response.msg }}
        />
      )}
      {!response && (
        <Form onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <label htmlFor="email" className="label heading">
                Get Updates:
              </label>
              <div className="field has-addons">
                <div className="control">
                  <Field
                    className="input"
                    component="input"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Your email address"
                    // autoFocus
                  />
                </div>
                <div className="control">
                  <button className="button is-primary" type="submit">
                    Sign Up
                  </button>
                </div>
              </div>
            </form>
          )}
        </Form>
      )}
    </div>
  );
}

export default EmailSignup;
