import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import NetlifyForm from 'react-netlify-form';
import Layout from '../components/layout';
// import Hero from '../components/hero';
import SEO from '../components/seo';
import Field from '../components/field';
import states from '../data/states.json';
import { useCookies } from 'react-cookie';
import { DateTime } from 'luxon';

function CTA({ name, buttonText, buttonPrimary, content }) {
  const [cookies, setCookie] = useCookies(['ctaView']);
  // const [cookieCheck
  const [modalActive, setModalActive] = useState(false);

  const cookieView = newName => {
    setCookie(name, newName, {
      path: '/',
      maxAge: DateTime.local().plus({ minutes: 1 }).toSeconds(),
    });
  };

  // cookieView(DateTime.local().toISO())

  const closeModal = () => {
    setModalActive(false);
  };

  const openModal = () => {
    setModalActive(true);
    cookieView(DateTime.local().toISO());
  };

  // useEffect(() => {
  //   if (!cookies.ctaView) {
  //     openModal();
  //   }
  // }, [cookies]);

  return (
    <div>
      <button
        type="button"
        className={buttonPrimary ? 'button is-primary' : 'button'}
        onClick={openModal}
      >
        {buttonText}
      </button>
      {modalActive && (
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">I'm With Vanna!</p>
              <button className="delete" aria-label="close" onClick={closeModal}></button>
            </header>
            <NetlifyForm
              name="Voter ID"
              recaptcha={{
                sitekey: '6Lc9qO4UAAAAAMqtugSDYdFnZcB6JPBTMaFjIr76',
                size: 'invisible',
              }}
            >
              {({ recaptcha, loading, error, success }) => (
                <section class="modal-card-body">
                  <div>
                    {error && <div className="notification is-danger">Please try again.</div>}
                    {success && <div>Thank you for signing up!</div>}
                    {!loading && !success && (
                      <>
                        <div className="field is-horizontal">
                          <div className="field-body">
                            <Field
                              type="text"
                              name="firstName"
                              className="input is-grouped"
                              id="firstName"
                              required
                              label="First Name"
                            />
                            <Field
                              type="text"
                              name="lastName"
                              className="input is-grouped"
                              id="lastName"
                              required
                              label="Last Name"
                            />
                          </div>
                        </div>

                        <Field
                          type="email"
                          name="email"
                          className="input"
                          id="email"
                          placeholder="yourname@something.com"
                          required
                          label="Email Address"
                        />

                        <Field
                          type="text"
                          name="phone"
                          className="input"
                          id="phone"
                          placeholder="(978) 123-4567"
                          label="Phone Number"
                        />

                        <Field
                          type="text"
                          name="addressStreet"
                          id="addressStreet"
                          label="Street Address"
                        />

                        <div className="field is-horizontal">
                          <div className="field-body">
                            <Field
                              type="text"
                              name="addressCity"
                              id="addressCity"
                              label="City/Town"
                            />
                            <Field
                              type="select"
                              name="addressState"
                              label="State"
                              defaultValue="MA"
                              options={states}
                            />{' '}
                            <Field
                              type="text"
                              name="addressZip"
                              label="Zip Code"
                              pattern="(\d{5}([\-]\d{4})?)"
                              required
                            />
                          </div>
                        </div>

                        <div className="field">
                          <div className="control">
                            <button className="button is-primary" type="submit">
                              Sign Up
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                    {recaptcha}
                  </div>
                </section>
              )}
            </NetlifyForm>
          </div>
          <button className="modal-close is-large" onClick={closeModal} aria-label="close"></button>
        </div>
      )}
    </div>
  );
}

CTA.propTypes = {};
CTA.defaultProps = {};

export default CTA;
