import React, { useState } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';

import Logo from './logoSmall';

export default function Header() {
  const [navActive, setNavActive] = useState(false);
  const menuClass = navActive ? 'navbar-menu is-active' : 'navbar-menu';
  return (
    <>
      <nav
        className="navbar is-primary"
        role="navigation"
        aria-label="main navigation"
        style={{ borderBottom: '1px solid rgba(255,255,255,0.3' }}
      >
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <Logo width="200px" height="auto" fill="#fff" />
          </Link>
          <a
            role="button"
            href="/"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded={!navActive}
            data-target="navbarMenu"
            onClick={event => {
              event.preventDefault();
              setNavActive(!navActive);
            }}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarMenu" className={menuClass}>
          <div className="navbar-end">
            <Link to="/get-involved" className="navbar-item has-text-weight-bold">
              Get Involved
            </Link>
            <a
              href="https://facebook.com/voteforvanna"
              className="navbar-item has-text-weight-bold"
            >
              <div className="icon is-hidden-mobile">
                <FontAwesomeIcon size="2x" icon={faFacebookSquare} />
              </div>
              <span className="is-hidden-desktop">Facebook</span>
            </a>
            <a
              href="https://instagram.com/voteforvanna"
              className="navbar-item has-text-weight-bold"
            >
              <div className="icon is-hidden-mobile">
                <FontAwesomeIcon size="2x" icon={faInstagram} />
              </div>
              <span className="is-hidden-desktop">Instagram</span>
            </a>
            <div className="navbar-item">
              <div className="is-block-mobile">
                <a
                  href="https://secure.actblue.com/contribute/page/vannahoward"
                  className="button is-fullwidth is-success"
                >
                  <strong>Donate</strong>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
