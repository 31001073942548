/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';

// import { useStaticQuery, graphql } from 'gatsby';
import './app.scss';
import CTA from './cta';

import Header from './header';
import Footer from './footer';

const Layout = ({ children, container }) => {
  const mainClass = container ? 'container' : '';
  return (
    <>
      <Header />
      <main className={mainClass} style={{ minHeight: '80vh' }}>
        {children}
      </main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  container: PropTypes.bool,
};

Layout.defaultProps = {
  container: false,
};

export default Layout;
