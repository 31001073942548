import React from 'react';
import PropTypes from 'prop-types';

function LogoSmall({ fill, width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 445 41.84"
      style={{ width: width, height: height }}
    >
      <path
        d="M19.43 41.22c-.18.35-.53.35-.7 0L0 1.01h11.36l8.14 18.55 7.9-18.55h10.83l-18.8 40.21zm20.6-.41H29.62L48.99.31c.18-.41.53-.41.7 0l19.32 40.5H58l-1.52-3.39H41.5l-1.47 3.39zm8.84-20.6l-3.98 9.25h8.08l-4.1-9.25zm33.83.76v19.84H72.69V.48c0-.35.18-.64.82-.12l24.99 20.6V1.01h10.01V41.4c0 .47-.18.64-.82.12L82.7 20.97zm42.73 0v19.84h-10.01V.48c0-.35.18-.64.82-.12l24.99 20.6V1.01h10.01V41.4c0 .47-.18.64-.82.12l-24.99-20.55zm39.92 19.84h-10.42L174.3.31c.18-.41.53-.41.7 0l19.32 40.5h-11l-1.52-3.39h-14.98l-1.47 3.39zm8.83-20.6l-3.98 9.25h8.08l-4.1-9.25zm61.64-19.2h3.8v39.8h-3.8V27.7H214.1v13.11h-3.8V1.01h3.8v23.3h21.72V1.01zm11.35 19.9C247.17 9.15 256.3.54 267.6.54c11.3 0 20.43 8.6 20.43 20.37s-9.13 20.37-20.43 20.37c-11.3 0-20.43-8.6-20.43-20.37zm36.87 0c0-9.66-6.97-16.92-16.45-16.92s-16.45 7.26-16.45 16.92c0 9.66 6.97 16.92 16.45 16.92 9.49 0 16.45-7.26 16.45-16.92zm6.32-19.9h3.98l9.72 30.96 12-31.9h.29l12.06 31.9 9.66-30.96h3.75L328.7 41.57h-.29L316.24 9.2l-12.41 32.37h-.23L290.36 1.01zm53.44 39.8H340L356.74.07h.29l16.8 40.74h-3.92l-2.75-6.91h-20.55l-2.81 6.91zm13.11-32.37l-8.96 22.07h17.79c.07 0-8.83-22.07-8.83-22.07zm48.23 32.37h-4.62l-7.9-10.18c-1.05.18-2.11.23-3.28.23h-4.86v9.95h-3.8V1.01h8.66c10.83 0 16.45 6.38 16.45 14.98 0 6.38-3.16 11.59-9.31 13.76l8.66 11.06zM389.1 27.52c9.07 0 12.7-4.86 12.7-11.53 0-6.67-3.69-11.59-12.7-11.59h-4.62v23.12h4.62zm24.94-26.51h9.95c13.17 0 21.01 8.14 21.01 19.9s-7.78 19.9-21.01 19.9h-9.95V1.01zm9.6 36.35c11.76 0 17.38-6.79 17.38-16.45S435.4 4.46 423.64 4.46h-5.79v32.9h5.79z"
        className="st0"
        fill={fill}
      />
    </svg>
  );
}

LogoSmall.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

LogoSmall.defaultProps = {
  fill: 'rgba(0,0,0,.5)',
  width: '200px',
  height: '',
};

export default LogoSmall;
