import React from 'react';
import PropTypes from 'prop-types';

function Field({ type, name, label, options, required, ...otherProps }) {
  const field = type => {
    switch (type) {
      case 'select':
        return (
          <div className="field">
            <div className="control">
              <label htmlFor={name} className="label">
                {label}
              </label>
              <div className="select is-fullwidth">
                <select id={name} name={name} {...otherProps}>
                  {options && options.map(o => <option value={o.value}>{o.label}</option>)}
                </select>
              </div>
            </div>
          </div>
        );
      case 'checkbox':
        return (
          <div className="field" style={{ marginTop: 24, marginBottom: 24 }}>
            <input
              type="checkbox"
              id={name}
              name={name}
              className="is-checkradio has-background-color is-white"
              {...otherProps}
            />
            <label htmlFor={name}>{label}</label>
          </div>
        );

      case 'checkboxes':
        return (
          <div className="field">
            <div className="control">
              <label htmlFor={name} className="label">
                {label}
              </label>
              {options &&
                options.map(o => (
                  <div className="field">
                    <input
                      type="checkbox"
                      id={o.value}
                      name={name}
                      className="is-checkradio has-background-color is-white"
                    />
                    <label htmlFor={o.value}>{o.label}</label>
                  </div>
                ))}
            </div>
          </div>
        );
      case 'email':
        return (
          <div className="field">
            <div className="control">
              <label htmlFor={name} className="label">
                {label}
              </label>
              <input
                type={type}
                id={name}
                name={name}
                className="input"
                required={required}
                {...otherProps}
              />
            </div>
          </div>
        );
      case 'textarea':
        return (
          <div className="field">
            <div className="control">
              <label htmlFor={name} className="label">
                {label}
              </label>
              <textarea
                id={name}
                name={name}
                className="textarea"
                rows="4"
                required={required}
                {...otherProps}
              />
            </div>
          </div>
        );
      default:
        return (
          <div className="field">
            <div className="control">
              <label htmlFor={name} className="label">
                {label}
              </label>
              <input
                type={type}
                id={name}
                name={name}
                className="input"
                required={required}
                {...otherProps}
              />
            </div>
          </div>
        );
    }
  };

  return field(type);
}

Field.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
  required: PropTypes.bool,
};

export default Field;
